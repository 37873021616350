<template>
  <nav class="relative flex flex-wrap items-center justify-between px-2 py-3 bg-white-500 mb-3">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative flex justify-between lg:w-auto  px-4 lg:static lg:block lg:justify-start">
        <a class="text-ml font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-gray-600 body-font" href="#pablo">
          MK FOR PROGRAMMING AND DEVELOPMENT
        </a>
        <button class="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button" v-on:click="toggleNavbar()">
          <font-awesome-icon icon="bars" />
        </button>
      </div>
      <div v-bind:class="{'hidden': !showMenu, 'flex': showMenu}" class="lg:flex lg:flex-grow items-center">
        <ul class="flex flex-col lg:flex-row list-none ml-auto">
          <li class="nav-item">
            <a class="px-3 py-2 flex items-center text-ml uppercase font-bold leading-snug text-gray-600 body-font hover:opacity-75" href="#pablo">
              <i class=" text-lg leading-lg text-gray-600 body-font opacity-75" /><span class="ml-2">About</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="px-3 py-2 flex items-center text-ml uppercase font-bold leading-snug text-gray-600 body-font hover:opacity-75" href="#pablo">
              <i class=" text-lg leading-lg text-gray-600 body-font opacblackity-75" /><span class="ml-2">Contact Us</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="px-3 py-2 flex items-center text-ml uppercase font-bold leading-snug text-gray-600 body-font hover:opacity-75" href="#pablo">
              <i class="text-xl leading-lg text-gray-600 body-font opacity-75" /><span class="ml-2">Privacy Policy</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "pink-navbar",
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    toggleNavbar: function(){
      this.showMenu = !this.showMenu;
    }
  }
}
</script>