<template>
<section class="text-gray-600 body-font">
  <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
	<div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
<h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Cirrus: Know what is happening in Qatar</h1>
<p class="mb-8 leading-relaxed"><span class="font-medium"> Cirrus </span> the ultimate guide to discovering all the exciting activities,
	exhibitions, restaurants, and entertainment happening in Qatar! Whether you're looking to try a new restaurant,
	catch a live performance, or find something fun to do on the weekends, our app has you covered. With constantly
	updated listings and easy-to-use filters, you can easily find events and activities that fit your interests and schedule.
	Plus, with in-app maps and directions, it's never been easier to find your way to the hottest spots in town.
	Download our app today and start exploring all that your city has to offer!
</p>
<!-- <div class="flex w-full md:justify-start justify-center items-end">
		<div class="relative mr-4 md:w-full lg:w-full xl:w-1/2 w-2/4">
<label for="hero-field" class="leading-7 text-sm text-gray-600">Placeholder</label>
<input type="text" id="hero-field" name="hero-field" class="w-full bg-gray-100 rounded border bg-opacity-50 border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
		</div>
		<button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
</div>
<p class="text-sm mt-2 text-gray-500 mb-8 w-full">Neutra shabby chic ramps, viral fixie.</p>
<div class="flex lg:flex-row md:flex-col">


</div> -->
<div class="flex justify-center">
		<button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Promote your Business or Event in Cirrus</button>
		<!-- <button class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button> -->
</div>
	</div>
	<div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
  <img class="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600">
	</div>
  </div>
</section>
</template>